import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "./image"
import style from "./project-list.module.scss"

const ProjectList = ({ imgClassName }) => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(
        sort: { fields: [relativePath], order: ASC }
        filter: {
          childImageMetadata: { internal: { type: { eq: "ImageMetadata" } } }
          relativePath: { ne: "landing-page.png" }
        }
      ) {
        group(field: relativeDirectory, limit: 1) {
          edges {
            node {
              relativeDirectory
              ...Image
            }
          }
        }
      }
      info: allFile(filter: { name: { eq: "info" } }) {
        edges {
          node {
            relativeDirectory
            childYaml {
              title
              style {
                class
                numberSvg
                sections {
                  class
                  svg
                }
              }
            }
          }
        }
      }
    }
  `)

  const info = Object.fromEntries(
    data.info.edges.map(({ node }) => [node.relativeDirectory, node.childYaml])
  )

  return (
    <ul className={style.list}>
      {data.images.group.map(({ edges }, index) => {
        const { relativeDirectory, childImageMetadata } = edges[0].node
        let title = relativeDirectory
        let linkClassName = style.serif
        const currentInfo = info[relativeDirectory]
        let numberSvg
        if (currentInfo) {
          title = currentInfo.title
          const titleStyle = currentInfo.style
          if (titleStyle) {
            numberSvg = titleStyle.numberSvg
            linkClassName = style[titleStyle.class] || linkClassName
            title = title.split("\t").map((section, index) => {
              const sectionStyle =
                (titleStyle.sections && titleStyle.sections[index]) ||
                titleStyle
              return (
                <span className={style[sectionStyle.class]} key={index}>
                  {section}
                  {sectionStyle.svg &&
                    React.createElement(
                      require(`../assets/doodles/${sectionStyle.svg}.inline.svg`)
                    )}
                </span>
              )
            })
          }
        }
        return (
          <li key={index}>
            <Link to={`/${relativeDirectory}/`} className={linkClassName}>
              <span>
                ({index})
                {numberSvg &&
                  React.createElement(
                    require(`../assets/doodles/${numberSvg}.inline.svg`)
                  )}
              </span>{" "}
              {title}
            </Link>
            {imgClassName && (
              <Image className={imgClassName} metadata={childImageMetadata} />
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default ProjectList
