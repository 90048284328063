import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsList from "../components/project-list"
import style from "./projects.module.scss"

const ProjectsPage = () => (
  <Layout page="projects" fixedHeight>
    <SEO title="Projects" />
    <nav className={style.nav}>
      <ProjectsList imgClassName={style.img} />
    </nav>
  </Layout>
)

export default ProjectsPage
